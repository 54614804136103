import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CmmApiService } from './helpers/cmm-api.service';
import { LoggingService } from './helpers/logging.service';
import { MessageService } from './message.service';
import { Release } from './release';

@Injectable({
  providedIn: 'root',
})
export class ReleasesService extends CmmApiService {
  private releaseNotesURL = CmmApiService.API_URL + '/github/release-notes';

  constructor(
    protected override messageService: MessageService,
    protected override http: HttpClient,
    protected override loggingService: LoggingService,
  ) {
    super('ReleasesService', messageService, http, loggingService);
  }

  /** GET release notes from the server */
  getReleaseNotes(version: string): Observable<Release | null> {
    const url = `${this.releaseNotesURL}/${version}`;

    return this.http.get<Release>(url, this.httpOptions).pipe(
      tap((_) => this.log('fetched release notes')),
      catchError(
        this.handleError<Release>(`getReleaseNotes version=${version}`),
      ),
      this.trace('getReleaseNotes'),
    );
  }

  /** GET release notes from the server */
  getAllReleaseNotes(mode: 'beta' | 'live'): Observable<Release[]> {
    const url = `${this.releaseNotesURL}/all/${mode}?limit=10`;

    return this.http.get<Release[]>(url, this.httpOptions).pipe(
      tap((_) => this.log('fetched all release notes')),
      catchError(this.handleError<Release[]>(`getReleaseNotes mode=${mode}`)),
      map((notes) => notes ?? []),
      this.trace('getAllReleaseNotes'),
    );
  }
}
