import { Injectable } from '@angular/core';

//Websocket Pusher
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { Channel } from 'laravel-echo/dist/channel';
import { NEVER, Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { CmmApiService } from './helpers/cmm-api.service';
import { AuthService } from './auth.service';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

type SocketEventName =
  | 'notification.created'
  | 'notification.updated'
  | 'notification.deleted';

export type SocketEventData<T = any> = {
  type: SocketEventName;
  data: T;
};

type ChannelArray = {
  [key: string]: {
    listener: Observable<SocketEventData>;
    channel: Channel;
    events: SocketEventName[];
  };
};

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private echo: Echo | null = null;
  private channels: ChannelArray = {};

  private envName = '';

  private isAuthenticated$!: Observable<boolean>;

  constructor(private authService: AuthService) {
    this.setupEcho();
  }

  private setupEcho() {
    // FIXME: Remove once websockets are back to working on Vapor
    this.isAuthenticated$ = NEVER;
    return;

    this.isAuthenticated$ = of('').pipe(
      tap((token) => {
        (window as any).Pusher = Pusher;

        this.envName = environment.envName;

        let echoSettings = null;
        if (this.envName !== 'local') {
          echoSettings = {
            broadcaster: 'pusher',
            enabledTransports: ['ws', 'wss'],
            key: 'cmm-api',
            cluster: 'cmm1',
            wsHost: 'creekmore.marketing',
            wssHost: 'creekmore.marketing',
            wsPort: 6001,
            wssPort: 6001,
            //wsPath: 'api',
            forceTLS: true,
            disableStats: true,
            encrypted: true,
            authEndpoint: `${CmmApiService.API_URL}/broadcasting/auth`,
            auth: {
              headers: {
                Authorization: 'Bearer ' + token,
                Accept: 'application/json',
              },
            },
          };
        } else {
          echoSettings = {
            broadcaster: 'pusher',
            enabledTransports: ['ws'],
            key: 'cmm-api',
            cluster: 'cmm1',
            wsHost: '127.0.0.1',
            wsPort: 6001,
            //wsPath: 'api',
            forceTLS: false,
            disableStats: true,
            encrypted: false,
            authEndpoint: `${CmmApiService.API_URL}/broadcasting/auth`,
            auth: {
              headers: {
                Authorization: 'Bearer ' + token,
                Accept: 'application/json',
              },
            },
          };
        }

        this.echo = (window as any).Echo = new Echo(echoSettings);
      }),
      map((_) => true),
      shareReplay(1),
    );

    this.isAuthenticated$.subscribe();
  }

  /**
   * Once a channel has been first listened to, the events cannot be changed
   * @param channelName Name of the channel to connect to
   * @param eventNames Array of events to listen to
   * @returns Observable of the channel listener with event data
   */
  public listen<T = any>(
    channelName: string,
    eventNames: SocketEventName[],
  ): Observable<SocketEventData<T>> {
    return this.isAuthenticated$.pipe(
      switchMap((_) => {
        if (!this.echo) return NEVER; // an error occurred

        let obs: Observable<SocketEventData<T>> | null = null;

        if (!this.channels[channelName]) {
          let channel = this.echo.private(channelName);
          obs = new Observable<SocketEventData<T>>((observer) => {
            eventNames.forEach((event) => {
              channel.listen(`.${event}`, (data: T) => {
                observer.next({
                  type: event,
                  data: data,
                });
              });
            });
          });

          this.channels[channelName] = {
            listener: obs,
            channel: channel,
            events: eventNames,
          };
        } else {
          obs = this.channels[channelName].listener;
        }

        return obs;
      }),
    );
  }
}
